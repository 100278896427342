<template>
  <div class="admin-add-territory">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="territory"
      :handleUpdate="({ key }, v) => (territory[key] = v)"
    />
  </div>
</template>

<script>
import territorySchema from "@/validation-schemas/territory";
import FormGenerator from "@/components/form-generator.vue";
export default {
  components: { FormGenerator },
  name: "admin-add-territory",
  data() {
    return {
      territory: {
        name: "",
        iso_code: ""
      },
      error: null,
      loading: false
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string"
        },
        {
          key: "code",
          label: "code",
          type: "string"
        },
        {
          text: "add-territory",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { territory } = this;
      const validation = territorySchema.validate(territory, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("territories/create", {
            ...territory,
            parent_territory_id: territory.parent_territory_id
              ? territory.parent_territory_id.id
              : null
          });
          this.$emit("added", res.data);
          this.territory = {
            name: "",
            code: ""
          };
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
