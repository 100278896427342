<template>
  <div class="admin-territories">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddTerritory = true">{{
        $t("add-territory")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="territories/paginate"
      :headers="headers"
      ref="table"
      :limit="50"
    >
      <template #cell(parent_channel)="{ item }">{{
        item.parent_channel ? item.parent_channel.name : ""
      }}</template>
      <template #cell(does_allow_free_listing)="{ item }">{{
        $t(item.does_allow_free_listing ? "yes" : "no")
      }}</template>
      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <b-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </b-button>
      </template>
    </advanced-data-table>

    <!-- SalesChannel edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-territory>
        <EditTerritory :territory="editedItem" />
      </template>
    </ebp-modal>

    <!-- SalesChannel add modal -->
    <ebp-modal :open.sync="showAddTerritory" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-territory>
        <AddTerritory @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import territorySchema from "@/validation-schemas/territory";
import AddTerritory from "@/views/admin/system/territories/Add";
import EditTerritory from "@/views/admin/system/territories/Edit";

export default {
  name: "admin-territories",
  components: {
    AdvancedDataTable,
    AddTerritory,
    EditTerritory
  },
  data() {
    return {
      headers: ["name", "code"],
      editedItem: null,
      saving: false,
      open: false,
      showAddTerritory: false,
      error: null,
      editTabs: ["edit-territory"],
      addTabs: ["add-territory"]
    };
  },
  methods: {
    added() {
      this.showAddTerritory = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(territory) {
      if (confirm(this.$t("remove-message", { name: territory.name }))) {
        try {
          await this.$store.dispatch("territories/delete", territory.id);
          this.$refs.table.change("id", territory.id);
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(territory) {
      this.editedItem = territory;
      this.open = true;
    },
    async save() {
      this.error = null;
      const territory = this.editedItem;
      const validation = territorySchema.validate(territory);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("territories/update", territory);
          this.$refs.table.change("id", territory.id, territory);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
